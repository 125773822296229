import React, { useMemo } from "react";
import Dashboard from "../components/Dashboard";
import { useSelector } from "react-redux";
import Roles from "../components/Admin/Roles";
import User from "../components/User";
import AdminUser from "../components/Admin/AdminUser";
import Store from "../components/Admin/Store";
import Category from "../components/Admin/Category";
import OrderList from "../components/Admin/OrderList";
import Faq from "../components/User/Faq";
import Staff from "../components/Staff";
import ActivityLog from "../components/Admin/ActivityLog";
import ContactUs from "../components/User/ContactUs";
import CMS from "../components/CMS";
import TransactionType from "../components/User/TransactionType";
import Banner from "../components/User/Banner";
import Transactions from "../components/User/UserDetailView/Transactions";
import Appearance from "../components/Admin/Settings/Appearance";
import HomePage from "../components/CMS/HomePage";
import VideoContent from "../components/CMS/VideoContent";
import CustomOffers from "../components/User/CustomOffers";
import BestSeller from "../components/Reports/BestSeller";
import MostSpentCustomer from "../components/Reports/MostSpentCustomer";
import EarningAndRedemption from "../components/Reports/EarningAndRedemption";
import RepeatCustomer from "../components/Reports/RepeatCustomer";
import RepeatOrder from "../components/Reports/RepeatOrder";
import GiftCards from "../components/User/GiftCards";
import Feedback from "../components/User/Feedback";
import MyCards from "../components/Reports/MyCards";
import WalletTransaction from "../components/Reports/WalletTransaction";
import VideoBanner from "../components/User/VideoBanner";
import CustomNotification from "../components/CMS/CustomNotification";
import DeliveryAreas from "../components/Admin/DeliveryAreas";
import Survey from "../components/User/Survey";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];
  const AdminComponent = [
    {
      component: <Roles />,
      permission: "Roles",
      path: "roles",
    },
    {
      component: <AdminUser />,
      permission: "Admin",
      path: "admin-user",
    },
    {
      component: <Store />,
      permission: "Store",
      path: "store",
    },
    {
      component: <Category />,
      permission: "Category",
      path: "category",
    },
    {
      component: <OrderList />,
      permission: "Orders",
      path: "orders",
    },
    {
      component: <ActivityLog />,
      permission: "Activity Log",
      path: "activity",
    },
    {
      component: <DeliveryAreas />,
      permission: "Delivery Areas",
      path: "delivery-areas",
    },
    {
      component: <Appearance />,
      permission: "Settings",
      path: "settings",
    },
  ];
  const UserComponent = [
    {
      component: <User />,
      permission: "Users",
      path: "users",
    },
    {
      component: <Banner />,
      permission: "Banner",
      path: "banner",
    },
    {
      component: <ContactUs />,
      permission: "Contact Us",
      path: "contact-us",
    },
    {
      component: <Faq />,
      permission: "FAQ",
      path: "faq",
    },
    {
      component: <Survey />,
      permission: "SURVEY",
      path: "survey",
    },
    {
      component: <TransactionType />,
      permission: "Transaction Types",
      path: "transaction-type",
    },
    {
      component: <Transactions />,
      permission: "Transactions",
      path: "transactions",
    },
    {
      component: <CustomOffers />,
      permission: "Offers",
      path: "offers",
    },
    {
      component: <GiftCards />,
      permission: "Gifts Cards",
      path: "gift-cards",
    },
    {
      component: <Feedback />,
      permission: "Feed Back",
      path: "feedback",
    },
    {
      component: <VideoBanner />,
      permission: "Video Content",
      path: "video-content",
    },
  ];
  const StaffComponent = [
    {
      component: <Staff />,
      permission: "Staffs",
      path: "list",
    },
  ];
  const ContentComponent = [
    {
      component: <CMS />,
      permission: "Policies and Terms",
      path: "policies-terms",
    },
    {
      component: <HomePage />,
      permission: "Home",
      path: "home-page",
    },
    {
      component: <VideoContent />,
      permission: "Video Content",
      path: "video",
    },
    {
      component: <CustomNotification />,
      permission: "Custom Notification",
      path: "custom-notification",
    },
  ];
  const ReportComponent = [
    {
      component: <BestSeller />,
      permission: "Best Seller",
      path: "best-seller",
    },
    {
      component: <MostSpentCustomer />,
      permission: "Most Spent Customer",
      path: "most-spent-customer",
    },
    {
      component: <EarningAndRedemption />,
      permission: "Earning And Redemption",
      path: "earning-redemption",
    },
    {
      component: <RepeatCustomer />,
      permission: "Repeat Customer",
      path: "repeat-customer",
    },
    {
      component: <RepeatOrder />,
      permission: "Repeat Order",
      path: "repeat-order",
    },
    {
      component: <MyCards />,
      permission: "Gift Card Purchase",
      path: "gift-cards-purchase",
    },
    {
      component: <WalletTransaction />,
      permission: "Wallet Transaction",
      path: "wallet-transaction",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );
    if (!menus?.length) {
      menus = [];
    }
    menus = [...menus];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent?.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedAdminComponent = AdminComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedUserComponent = UserComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedStaffComponent = StaffComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedContentComponent = ContentComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedReportComponent = ReportComponent?.filter(({ permission }) =>
    hasPermission(permission)
  );

  return {
    allowedDashboardComponent,
    allowedContentComponent,
    allowedStaffComponent,
    allowedAdminComponent,
    allowedUserComponent,
    allowedReportComponent,
  };
};

export default useRoutes;
