import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api/Admin";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}filter=${params?.filter || "all"}&${getParams("sort")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || ""
          }&${getParams("template_id")}&${getParams("type")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const report = createApi({
  reducerPath: "reportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Report",
    "BestSeller",
    "EarningRedemption",
    "Repeat Order",
    "Repeat Customer",
    "Most Spent Customer",
    "My Cards",
  ],
  endpoints: (builder) => ({
    getBestSellerListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/dashboard/best-seller-list",
      }),
      providesTags: ["BestSeller"],
    }),
    updateBestSellerTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["BestSeller"],
    }),
    getEarningRedemptionListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/user/earn-redemption-list",
      }),
      providesTags: ["EarningRedemption"],
    }),
    updateEarningRedemptionTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["EarningRedemption"],
    }),
    getMostSpentCustomerListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/user/mostspent-customer-list",
      }),
      providesTags: ["Most Spent Customer"],
    }),
    updateMostSpentCustomerTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Most Spent Customer"],
    }),
    getRepeatOrderListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/order/repeat-order-list",
      }),
      providesTags: ["Repeat Order"],
    }),
    updateRepeatOrderTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Repeat Order"],
    }),
    getRepeatCustomerListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/user/repeat-customer-list",
      }),
      providesTags: ["Repeat Customer"],
    }),
    updateRepeatCustomerTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Repeat Customer"],
    }),
    //my cards report section starts
    getMyCardsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/my-cards/index",
      }),
      providesTags: ["My Cards"],
    }),
    updateMyCardsTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["My Cards"],
    }),
    getMyCardsBasicListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/my-cards/gift-cards",
      }),
      keepUnusedDataFor: 1,
    }),
    //my cards report section ends
    //wallet transaction starts
    getWalletTransactionListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/wallet-transaction/history-list",
      }),
      providesTags: ["My Cards"],
    }),
    updateWalletTransactionTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["My Cards"],
    }),
    getWalletTransactionBasicListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/wallet-transaction/wallet-filter",
      }),
      keepUnusedDataFor: 1,
    }),
    //wallet transaction ends

    //User Feedback starts
       getUserFeedbackListData: builder.query({
        query: (body) => ({
          method: "post",
          body,
          endpoint: "/survey/report",
        }),
        providesTags: ["User Feedback"],
      }),
      updateUserFeedbackTableHeadData: builder.mutation({
        query: (body) => ({
          method: "post",
          body,
          endpoint: `/user-field/store`,
        }),
  
        invalidatesTags: ["User Feedback"],
      }),
      getUserFeedbackBasicListData: builder.query({
        query: (values) => ({
          method: "get",
          params: values,
          endpoint: "/survey/report-basic-data",
        }),
        keepUnusedDataFor: 1,
      }),
      //User Feedback ends
  }),
});

export const {
  useGetBestSellerListDataQuery,
  useUpdateBestSellerTableHeadDataMutation,
  useGetEarningRedemptionListDataQuery,
  useUpdateEarningRedemptionTableHeadDataMutation,
  useGetMostSpentCustomerListDataQuery,
  useUpdateMostSpentCustomerTableHeadDataMutation,
  useGetRepeatOrderListDataQuery,
  useUpdateRepeatOrderTableHeadDataMutation,
  useGetRepeatCustomerListDataQuery,
  useUpdateRepeatCustomerTableHeadDataMutation,
  useGetMyCardsListDataQuery,
  useUpdateMyCardsTableHeadDataMutation,
  useGetMyCardsBasicListDataQuery,
  useGetWalletTransactionBasicListDataQuery,
  useGetWalletTransactionListDataQuery,
  useUpdateWalletTransactionTableHeadDataMutation,
  useGetUserFeedbackBasicListDataQuery,
  useGetUserFeedbackListDataQuery,
  useUpdateUserFeedbackTableHeadDataMutation,
} = report;
