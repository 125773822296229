import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useSurvey from "./useSurvey";
import AddSurvey from "./AddSurvey";

const Survey = () => {
   const {
    surveyList,
    surveyState,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    handleCreateClick,
    handleDeleteModal,
    handleClearClick,
    handlePagination,
    handleEditAction,
    updateSurveyFields,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useSurvey();

  return (
    <>
      <HeadingGroup
        title="Survey"
        className={`pro-mb-4`}
        buttonTitle={surveyList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={surveyState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: surveyState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        <Table
          multiSelect={false}
          data={surveyList?.data?.result || []}
          uniqueID={"id"}
          fields={surveyList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={surveyState?.clearSelection}
          perpage={surveyState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={surveyList?.data?.delete_permission}
          editable={surveyList?.data?.edit_permission}
          showCheckBox={
            surveyList?.data?.delete_permission || surveyList?.data?.edit_permission
          }
        />
        {surveyList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={surveyState?.currentPage}
            totalPageCount={Math.ceil(
              surveyList.data.total_count / surveyState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === surveyState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={surveyState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Survey ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={surveyList?.data?.fields || []}
              moduleId={surveyList?.data?.module_id}
              updateData={updateSurveyFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={surveyState?.showCreateModal}
          handleClose={handleCreateClick}
          title={surveyState?.isEdit ? "Update Survey" : "Add Survey"}
          backdrop={"static"}
        >
          <AddSurvey refetch={refetch} tierState={surveyState} isStickyFooter />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Survey;
