import { getAdminAxiosInstance } from "../../../api/Admin";

export const createSurvey = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/survey/create-question`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateSurvey = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/survey/update-question`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteSurvey = async (id) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(`/survey/delete-question?question_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

