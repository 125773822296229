import { getAdminAxiosInstance } from "../../api/Admin";

export const getBestSellerExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/dashboard/best-seller-report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getEarningRedemptionExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/user/earn-redemption-report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getMostSpentCustomerExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/user/mostspent-customer-report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getRepeatCustomerExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/user/repeat-customer-report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getRepeatOrderExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/order/repeat-order-report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getMyCardsExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/my-cards/report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getWalletTransactionExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(
      `/wallet-transaction/history-report`,
      params,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getUserFeedbackExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(
      `/survey/report-export`,
      params,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};