import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { createSurvey, updateSurvey } from "../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/User/Survey/surveySlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import useValidations from "../../../../utils/hooks/useValidations";
import { useGetSurveyBasicDataQuery } from "../../../../store/queries/User";

const useAddSurvey = (refetch) => {
  const dispatch = useDispatch();
  const [isSurveyCreate, setIsSurveyCreate] = useState(false);
  const { arabicOnlyRegex, arabicRegex, englishRegex } = useValidations();
  const { isEdit, editSurveyData } = useSelector((state) => state.survey);
  const { data: basicData = {}, isLoading: basicLoading } =
    useGetSurveyBasicDataQuery();

  useEffect(() => {
    let selectedStar = basicData?.data?.star_list?.find(
      (item) => item?.id === editSurveyData?.star
    );
    const formattedQuestions = editSurveyData?.questions?.map((question) => ({
      unique_id: question?.unique_id,
      question: question?.question ?? "",
      alias_question: question?.alias_question ?? "",
      answers: question?.answers?.map((answer) => ({
        unique_id: answer?.unique_id,
        answer: answer?.answer ?? "",
        alias_answer: answer?.alias_answer ?? "",
      })),
    }));
    if (isEdit && editSurveyData !== "") {
      formik?.setFieldValue("star", selectedStar ?? "");
      formik?.setFieldValue("sections", formattedQuestions ?? []);
      formik?.setFieldValue("status", editSurveyData?.status ?? "");
    }
    //eslint-disable-next-line
  }, [isEdit, editSurveyData,basicData]);

  const validation = Yup.object().shape({
    star: Yup.object().required("Select star"),
    sections: Yup.array().of(
      Yup.object().shape({
        question: Yup.string()
        .trim()
        .required("Question is required")
        .matches(englishRegex, "Enter in english letters"),
        alias_question: Yup.string()
        .trim()
        .required("Alias Question is required")
        .matches(arabicRegex, "Enter in arabic letters"),
        answers: Yup.array().of(
          Yup.object().shape({
            answer: Yup.string()
         .required(`Enter your  answers`)
        //  .min(5, "Answer which must be at least 5 characters long")
         .matches(englishRegex, "Enter in english letters"),
       alias_answer: Yup.string()
         .required(`Enter your  answers`)
        //  .min(5, "Answer which must be at least 5 characters long.")
         .matches(arabicRegex, "Enter in arabic letters"),
          })
        ),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      star: "",
      status: 2,
      sections: [
        {
          question: "",
          alias_question: "",
          answers: [
            {
              answer: "",
              alias_answer: "",
            },
          ],
        },
      ],
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        setIsSurveyCreate(true);
      const data = isEdit
        ? {
            question_id: editSurveyData?._id,
            star: values.star?.id, 
            status: values.status, 
            questions: values.sections.map((section) => ({
              unique_id: section?.unique_id,
              question: section?.question,
              alias_question: section?.alias_question,
              answers: section?.answers.map((answer) => ({
                unique_id: answer?.unique_id,
                answer: answer?.answer,
                alias_answer: answer?.alias_answer,
              })),
            })),
          }
        : {
          star: values.star?.id, 
          status: values.status, 
          questions: values.sections.map((section) => ({
            question: section?.question,
            alias_question: section?.alias_question,
            answers: section?.answers.map((answer) => ({
              answer: answer?.answer,
              alias_answer: answer?.alias_answer,
            })),
          })),
          };

        const response = await (isEdit ? updateSurvey(data) : createSurvey(data));
    
        if (response?.data?.status_code === 200) {
          toast.success(
            `Survey ${isEdit ? `Updated` : `Created`} Successfully`
          );
          setIsSurveyCreate(false);
          refetch();
        } else if(response?.data?.status_code === 422){
          toast.error(response?.data?.errors?.star);
        }
      } catch (error) {
        //TODO
        toast.error(error?.message);
        setIsSurveyCreate(false);
      } finally {
        closeModal();
        setIsSurveyCreate(false);
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  const addAnswer = (sectionIndex) => {
    const sections = [...formik.values.sections];
    sections[sectionIndex].answers.push({ answer: "", alias_answer: "" });
    formik.setValues({ ...formik.values, sections });
  };

  const addSection = () => {
    formik.setValues({
      ...formik.values,
      sections: [
        ...formik.values.sections,
        {
          question: "",
          alias_question: "",
          answers: [{ answer: "", alias_answer: "" }],
        },
      ],
    });
  };

  const removeAnswer = (sectionIndex, answerIndex) => {
    const sections = [...formik.values.sections];
    sections[sectionIndex].answers.splice(answerIndex, 1);
    formik.setValues({ ...formik.values, sections });
  };

  const removeSection = (sectionIndex) => {
    const sections = [...formik.values.sections];
    sections.splice(sectionIndex, 1);
    formik.setValues({ ...formik.values, sections });
  };

  // Disable "Add Section" button if the last section is not fully filled
  const isAddSectionDisabled =
    !formik.values.sections[formik.values.sections.length - 1]?.question ||
    !formik.values.sections[formik.values.sections.length - 1]?.alias_question ||
    formik.values.sections[formik.values.sections.length - 1]?.answers.some(
      (answer) => !answer?.answer.trim() || !answer?.alias_answer.trim()
    );

  // Disable "Add Answer" button if the last answer is not fully filled
  const isAddAnswerDisabled = (sectionIndex) => {
    const section = formik.values.sections[sectionIndex];
    return !section.answers.some(
      (answer) => answer?.answer.trim() && answer?.alias_answer.trim()
    );
  };

  return {
    formik,
    isEdit,
    isSurveyCreate,
    getFieldError,
    closeModal,
    basicData,
    addAnswer,
    addSection,
    removeAnswer,
    removeSection,
    isAddSectionDisabled,
    isAddAnswerDisabled,
  };
};

export default useAddSurvey;
