import {
    HeadingGroup,
    ModalLayout,
    Pagination,
    SearchFilters,
    Table,
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import { FaSort } from "react-icons/fa";
  import OrderColumn from "../../Global/OrderColumn";
  import EmptyData from "../../Global/EmptyData";
  import SendExcelData from "../../Admin/OrderList/SendExcelData";
  import CustomDateRangePicker from "../../Global/CustomDateRangePicker"
  import Select from "react-select";
  import useUserFeedback from "./useUserFeedback";
  
  const UserFeedback = () => {
    const {
      date,
      storeListObj,
      starListObj,
      isLoading,
      storeList,
      starList,
      isFetching,
      showEditModal,
      isExcelLinkSend,
      paginationOptions,
      userFeedbackList,
      userFeedbackState,
      handleUserFeedbackExcelDownload,
      updateUserFeedbackFields,
      handleEditColumnsClick,
      handleDateRangeChange,
      handleDateChange,
      handlePagination,
      handleClearClick,
      handlePageSize,
      handleSearch,
      sendExcelData,
      handleChangeStore,
      handleChangeStar,
      handleSort,
      modalClose,
      refetch,
      getRow,
    } = useUserFeedback();

    return (
      <>
        <HeadingGroup title="User Feedback" className={`pro-mb-4`}>
          <div className="col dropdown-filter pro-d-flex">
            <button
              className={`pro-btn pro-btn-outline-primary no-wrap pro-items-center pro-ms-3`}
              type="submit"
              onClick={handleUserFeedbackExcelDownload}
            >
              <span className="material-symbols-outlined x4">download</span>
              <span>{`Export Data`}</span>
            </button>
          </div>
        </HeadingGroup>
        <div className="col-auto pro-pt-3 pro-pb-6">
          <SearchFilters
            onSearchInput={handleSearch}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //   showClearFilters={userFeedbackState?.search === "" ? false : true}
            handleClear={handleClearClick}
            searchInputProps={{ value: userFeedbackState?.search }}
            handleActionClick={handleEditColumnsClick}
            searchable={true}
            dropDownFilter={
              <>
                <div className="col-auto">
                  <CustomDateRangePicker
                    defaultDateRange={date}
                    handleDateRangeChange={handleDateRangeChange}
                  />
                </div>
                <div className="col-auto dropdown-filter">
                  <Select
                    value={storeListObj}
                    onChange={handleChangeStore}
                    options={storeList}
                    placeholder="Select Store"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isClearable
                  />
                </div>
                <div className="col-auto dropdown-filter">
                  <Select
                    value={starListObj}
                    onChange={handleChangeStar}
                    options={starList}
                    placeholder="Select Rating"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isClearable
                  />
                </div>
              </>
            }
            onDateChange={handleDateChange}
            initialDateRange={date}
          />
          {userFeedbackList?.data?.result?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={userFeedbackList?.data?.result || []}
              uniqueID={"id"}
              fields={userFeedbackList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={userFeedbackState?.clearSelection}
              perpage={userFeedbackState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
  
          {userFeedbackList?.data?.result?.length > 0 && (
            <Pagination
              currentPage={userFeedbackState?.currentPage}
              totalPageCount={Math.ceil(
                userFeedbackState?.totalCount /
                userFeedbackState?.currentPageSize
              )}
              defaultValue={paginationOptions?.filter(
                (item) => item?.value === userFeedbackState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          <ModalLayout
            show={showEditModal}
            handleClose={handleEditColumnsClick}
            backdrop="static"
          >
            <div className="pro-m-5">
              <OrderColumn
                title={"Choose which columns you see"}
                refetchData={refetch}
                tableFields={userFeedbackList?.data?.fields || []}
                moduleId={userFeedbackList?.data?.module_id}
                updateData={updateUserFeedbackFields}
              />
            </div>
          </ModalLayout>
          <ModalLayout
            show={userFeedbackState.userFeedbackExcelSendModal}
            handleClose={handleUserFeedbackExcelDownload}
            title={`Send Excel Download Link`}
            backdrop="static"
          >
            <div className="pro-m-5">
              <SendExcelData
                isBestSeller
                handleCloseModal={modalClose}
                sendExcelData={sendExcelData}
                isExcelLinkSend={isExcelLinkSend}
              />
            </div>
          </ModalLayout>
        </div>
      </>
    );
  };
  
  export default UserFeedback;
  