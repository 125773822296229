import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getSurveyEditData,
  updateConfig,
} from "../../../store/slices/User/Survey/surveySlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteSurvey } from "./api";
import { useGetSurveyListDataQuery, useUpdateSurveyTableHeadDataMutation } from "../../../store/queries/User";

const useSurvey = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const surveyState = useSelector((state) => state.survey);
  const { selectedId } = useSelector((state) => state.survey);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: surveyList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetSurveyListDataQuery({
    page_size: surveyState?.currentPageSize,
    page: surveyState?.currentPage,
    sort: surveyState?.sortBy,
    sort_order: surveyState?.sortOrder,
    search: surveyState?.search,
  });

  const [updateSurveyFields] = useUpdateSurveyTableHeadDataMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (surveyState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = surveyState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      question: (field, data) => {
        if (Array.isArray(data?.[field])) {
          return (
            <ul
              className="pro-list"
            >
              {data[field].map((item, index) => (
                <li key={index} >
                  {item}
                </li>
              ))}
            </ul>
          );
        }
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getSurveyEditData(e?.[0]));
  };
  const handleDelete = async () => {
    const response = await deleteSurvey(selectedId);
    if (response && response.status === 200) {
      toast.success(`Survey deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  useEffect(() => {
    if (surveyState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [surveyState.clearSelection]);

  return {
    surveyList,
    surveyState,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    handleCreateClick,
    handleDeleteModal,
    handleClearClick,
    handlePagination,
    handleEditAction,
    updateSurveyFields,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useSurvey;
