import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Report/userFeedbackSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUserFeedbackExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";
import { useGetUserFeedbackBasicListDataQuery, useGetUserFeedbackListDataQuery, useUpdateUserFeedbackTableHeadDataMutation } from "../../../store/queries/Report";

const useUserFeedback = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const userFeedbackState = useSelector(
    (state) => state.userFeedback
  );
  const { data: basicData = {} } = useGetUserFeedbackBasicListDataQuery();

  const {
    data: userFeedbackList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetUserFeedbackListDataQuery({
    page_size: userFeedbackState?.currentPageSize,
    page: userFeedbackState?.currentPage,
    sort: userFeedbackState?.sortBy,
    sort_order: userFeedbackState?.sortOrder,
    search: userFeedbackState?.search,
    start_date: getFormatedDate(userFeedbackState?.startDate),
    end_date: getFormatedDate(userFeedbackState?.endDate),
    customer_key: userFeedbackState?.storeId,
    rating: userFeedbackState?.starId
  });
  const [updateUserFeedbackFields] =
  useUpdateUserFeedbackTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  useEffect(() => {
    if (userFeedbackList && userFeedbackList?.data?.total_count) {
      dispatch(
        updateConfig((state) => {
          state.totalCount = userFeedbackList?.data?.total_count ?? "0";
        })
      );
    }
    //eslint-disable-next-line
  }, [userFeedbackList?.data?.total_count]);
  const [date, setDate] = useState({
    startDate: userFeedbackState.startDate,
    endDate: userFeedbackState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (userFeedbackState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            userFeedbackState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleUserFeedbackExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.userFeedbackExcelSendModal =
          !state.userFeedbackExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.userFeedbackExcelSendModal = false;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(userFeedbackState.startDate ?? ""),
      end_date: getFormatedDate(userFeedbackState.endDate ?? ""),
      search: userFeedbackState?.search,
      customer_key: userFeedbackState?.storeId,
      rating: userFeedbackState?.starId,
      sort_order: userFeedbackState?.sortOrder,
      sort: userFeedbackState?.sortBy,
    };
    let response = await getUserFeedbackExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };
  const handleChangeStore = (e) => {
    dispatch(
      updateConfig((state) => {
        state.storeId = e?.customer_key
      })
    );
  };

  const handleChangeStar = (e) => {
    dispatch(
      updateConfig((state) => {
        state.starId = e?.value
      })
    );
  };

  let storeList = basicData?.store_list?.map((item) => {
    return {
      label: item?.name,
      value: item?._id,
      customer_key: item?.customer_key
    };
  });
  let starList = basicData?.star_list?.map((item) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });
  let storeListObj = storeList?.filter(
    (item) => item?.customer_key === userFeedbackState?.storeId
  );
  let starListObj = starList?.filter(
    (item) => item?.value === userFeedbackState?.starId
  );

  return {
    date,
    storeListObj,
    starListObj,
    isLoading,
    isFetching,
    storeList,
    starList,
    tableFields,
    showEditModal,
    isExcelLinkSend,
    paginationOptions,
    userFeedbackList,
    userFeedbackState,
    handleUserFeedbackExcelDownload,
    updateUserFeedbackFields,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleChangeStore,
    handleChangeStar,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useUserFeedback;
