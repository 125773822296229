import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  surveyData: [],
  editSurveyData: {},
  error: {},
};

export const getSurveyEditData = createAsyncThunk(
  "survey/question-details",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/survey/question-details?question_id=${id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyEditData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSurveyEditData.fulfilled, (state, action) => {
        state.editSurveyData = action.payload?.data;
      })
      .addCase(getSurveyEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.data;
      });
  },
});

export const { updateConfig } = surveySlice.actions;
export default surveySlice.reducer;
